<template>
  <div class="detailed_information">
    <div class="detailed_information_form">
      <el-form
        :model="detailedInfo"
        ref="detaildForm"
        label-position="left"
        :rules="rules"
        label-width="100px"
      >
        <!-- <el-form-item label="应用场景:" prop="solutionScene" style="width: 868px;">
          <Editorbar v-model="detailedInfo.solutionScene" :isClear="isClear" />
        </el-form-item> -->
        <!-- 修改-应用场景修改 -->
        <el-form-item
          label="应用场景:"
          prop="productScenes"
          v-for="(val, index) in detailedInfo.productScenes"
          :key="'index' + index"
        >
          <div style="display: flex">
            <el-select
              style="width: 627px"
              v-model="detailedInfo.productScenes[index]"
              placeholder="请选择"
              @change="
                (value) => {
                  changeSceneName(value, index);
                  // changeSceneName(value, detailedInfo.productScenes[index]);
                }
              "
              filterable
            >
              <el-option
                v-for="val1 in productScenesOptions"
                :key="'flag' + val1.id"
                :label="val1.sceneName"
                :value="val1.sceneName"
              >
              </el-option>
            </el-select>
            <div style="display: flex; font-size: 24px; margin-left: 20px">
              <span v-if="index == 0" style="color: #4e93fb" @click="addScenes">
                <i class="el-icon-circle-plus"></i>
              </span>
              <span
                v-if="index > 0"
                style="color: #ff7575"
                @click="delScenes(index)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </div>
          </div>
        </el-form-item>
        <!-- 新增-应用价值 -->
        <el-form-item label="应用价值:" prop="informationProductValues">
          <div>
            <div
              class="input_flex"
              v-for="(item, index) in detailedInfo.informationProductValues"
              :key="item.id"
            >
              <div class="general_inputs">
                <div class="valueType_flex">
                  <div style="margin-right: 20px">价值类型</div>
                  <el-radio-group v-model="item.valueType">
                    <el-radio label="提升">提升</el-radio>
                    <el-radio label="降低">降低</el-radio>
                  </el-radio-group>
                </div>
                <div class="textarea">
                  <el-input
                    v-model="item.valueTitle"
                    type="textarea"
                    placeholder="请输入应用价值标题"
                    maxlength="10"
                    show-word-limit
                  />
                </div>
                <!-- 滑块 -->
                <!-- <div class="block" style="margin-bottom: 20px">
                  <el-slider
                    v-model="item.value"
                    :step="10"
                    :marks="marks"
                    range
                    show-stops
                    @change="changeSlider"
                  >
                  </el-slider>
                </div> -->
                <div
                  style="display: flex; align-items: center; padding-top: 20px"
                >
                  <div class="percentage">
                    <el-input placeholder="" v-model="item.input1">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                  <div style="padding: 0px 10px">至</div>
                  <div class="percentage">
                    <el-input placeholder="" v-model="item.input2">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
              </div>

              <div style="display: flex; font-size: 24px; margin-left: 20px">
                <span
                  v-if="index == 0"
                  style="color: #4e93fb"
                  @click="addvalueTitle"
                  ><i class="el-icon-circle-plus" style="font-size: 24px"></i
                ></span>
                <span
                  v-if="index > 0"
                  style="color: #ff7575"
                  @click="delvalueTitle(index)"
                  ><i class="el-icon-delete" style="font-size: 24px"></i
                ></span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="产品说明:" prop="productExplain">
          <Editorbar
            v-model="detailedInfo.productExplain"
            :isClear="isClear"
            style="width: 868px"
          />
        </el-form-item>
        <!-- 多个 -->
        <el-form-item
          label="产品亮点:"
          prop="productLightSpots"
          v-for="(spots, si) in detailedInfo.productLightSpots"
          :key="si"
        >
          <div style="display: flex">
            <Editorbar
              v-model="detailedInfo.productLightSpots[si]"
              :isClear="isClear"
              style="width: 868px"
            />
            <div style="display: flex; font-size: 24px; margin-left: 20px">
              <span
                v-if="si == 0"
                style="color: #4e93fb"
                @click="addProductLightSpots"
              >
                <i class="el-icon-circle-plus" style=""></i>
              </span>
              <span
                v-if="si > 0"
                style="color: #ff7575"
                @click="delProductLightSpots(si)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="硬件说明:"
          prop="productHexPlains"
          v-for="(plains, pi) in detailedInfo.productHexPlains"
          :key="'pi' + pi"
        >
          <div style="display: flex">
            <Editorbar
              v-model="detailedInfo.productHexPlains[pi]"
              :isClear="isClear"
              style="width: 868px"
            />
            <div style="display: flex; font-size: 24px; margin-left: 20px">
              <span
                v-if="pi == 0"
                style="color: #4e93fb"
                @click="addProductHexPlains"
              >
                <i class="el-icon-circle-plus"></i>
              </span>
              <span
                v-if="pi > 0"
                style="color: #ff7575"
                @click="delProductHexPlains(pi)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="合作案例:"
          prop="productCases"
          v-for="(cases, cs) in detailedInfo.productCases"
          :key="'cs' + cs"
        >
          <div style="display: flex">
            <Editorbar
              v-model="detailedInfo.productCases[cs]"
              :isClear="isClear"
              style="width: 868px"
            />
            <div style="display: flex; font-size: 24px; margin-left: 20px">
              <span
                v-if="cs == 0"
                style="color: #4e93fb"
                @click="addProductCases"
              >
                <i class="el-icon-circle-plus"></i>
              </span>
              <span
                v-if="cs > 0"
                style="color: #ff7575"
                @click="delProductCases(cs)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </div>
          </div>
        </el-form-item>
        <!-- 新增 -->
        <el-form-item label="发票信息:" prop="productInvoice">
          <Editorbar
            v-model="detailedInfo.productInvoice"
            :isClear="isClear"
            style="width: 868px"
          />
        </el-form-item>
        <el-form-item label="服务保障:" prop="serviceGuarantee">
          <Editorbar
            v-model="detailedInfo.serviceGuarantee"
            :isClear="isClear"
            style="width: 868px"
          />
        </el-form-item>
        <el-form-item label="技术驱动:" prop="productAi">
          <!-- <el-select v-model="detailedInfo.solutionScene" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <!-- <el-radio v-model="detailedInfo.productAi" label="阿里AI"
            >阿里AI</el-radio
          >
          <el-radio v-model="detailedInfo.productAi" label="百度AI"
            >百度AI</el-radio
          >
          <el-radio v-model="detailedInfo.productAi" label="腾讯AI"
            >腾讯AI</el-radio
          >
          <el-radio v-model="detailedInfo.productAi" label="华为AI"
            >华为AI</el-radio
          > -->
          <el-checkbox-group v-model="detailedInfo.productAi">
            <el-checkbox label="阿里AI"></el-checkbox>
            <el-checkbox label="百度AI"></el-checkbox>
            <el-checkbox label="腾讯AI"></el-checkbox>
            <el-checkbox label="华为AI"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="产品附件:" prop="productFileList">
          <div
            class="file_flex"
            v-for="(el, i) in detailedInfo.productFileList"
            :key="i"
          >
            <div
              class="name_form"
              style="
                width: 600px;
                display: flex;
                justify-content: space-between;
              "
            >
              <!-- el.name -->
              <el-input
                v-model="detailedInfo.productFileList[i]"
                placeholder="请上传产品附件"
              />
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUploadprods"
                :http-request="
                  (res) => {
                    return uploadprods(res, i);
                  }
                "
              >
                <el-button
                  style="position: relative; left: -90px"
                  slot="trigger"
                  size="mini"
                  type="primary"
                  plain
                  >上传附件</el-button
                >
              </el-upload>
            </div>
            <div style="display: flex; font-size: 24px">
              <span v-if="i == 0" style="color: #4e93fb" @click="addUpload"
                ><i class="el-icon-circle-plus"></i
              ></span>
              <span v-if="i > 0" style="color: #ff7575" @click="delUpload(i)"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
          <span style="color: #909399"
            >支持doc、docx、xls、xlsx、ppt、pptx、pdf格式</span
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="bottom" v-if="this.enterpriseType">
      <el-button type="primary" @click="detailedUpdate">确定</el-button>
      <el-button type="info" @click="cancelClick">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import cos from "../../../utils/cos.js";
//  编辑产品附件详细信息 / 模糊查询所有场景知识数据
import { saveDetails, selectByScenarioName } from "../../../api/product";
import Editorbar from "../Editorbar";
export default {
  name: "detailedInformation",
  props: {
    // 表单数据
    detailedInfo: {
      type: Object,
    },
    // 类型-编辑/新增
    enterpriseType: {
      type: Number,
    },
  },
  components: {
    Editorbar,
  },
  data() {
    return {
      content: "",
      isClear: false,
      rules: {},
      productScenesOptions: [], //应用场景数据
      value2: null, //滑块数据
      options: [], //技术驱动数据
      // 标记
      marks: {
        0: "0",
        10: "10",
        20: "20",
        30: "30",
        40: "40",
        50: "50",
        60: "60",
        70: "70",
        80: "80",
        90: "90",
        100: "100",
      },
    };
  },
  watch: {
    // 监听应用价值
    "detailedInfo.informationProductValues": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.handleData();
          // newVal.forEach((el) => {
          //   this.detailedInfo.informationProductValues.push(el);
          // });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeSceneName(val, itemInput) {
      // this.productScenesOptions.forEach((item) => {
      //   if (item.id == val) {
      //     this.detailedInfo.productScenes[index] = item.sceneName;
      //   } else {
      //     itemInput = item.sceneName;
      //   }
      // });
      this.productScenesOptions.forEach((item) => {
        if (item.sceneName == val && itemInput.sceneName) {
          itemInput.sceneName = item.sceneName;
        } else {
          itemInput = item.sceneName;
        }
      });
    },
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    //编辑
    // async detailedUpdate() {
    //   const res = await saveDetails(this.detailedInfo);
    //   if (res.code == 200) {
    //     // 数据回显时处理应用价值input1-input2数据
    //     this.handleData();
    //     this.$message.success("详细信息修改成功");
    //     this.$router.go(-1);
    //   } else {
    //     this.$message.error(res.msg);
    //   }
    // },
    // 数据处理
    handleData() {
      // 应用价值字段存在
      if (this.detailedInfo.informationProductValues) {
        // 应用价值第一条对象的描述等于空，就什么都不带 valueTitle
        if (this.detailedInfo.informationProductValues[0].valueTitle == null) {
          this.detailedInfo.informationProductValues = null;
        } else {
          this.detailedInfo.informationProductValues.forEach((el) => {
            if (el.value) {
              this.$set(el, "input1", "");
              this.$set(el, "input2", "");
              el.value = el.value.split(",");
              el.input1 = el.value[0];
              el.input2 = el.value[1];
              el.value = "el.input1,el.input2";
            }
          });
        }
      } else {
        this.detailedInfo.informationProductValues = [
          {
            id: "", //主键
            informationId: this.productCode, //资讯ID
            value: "", //30%,50%
            valueTitle: "", //价值标题
            valueType: "", //价值类型
          },
        ];
      }
      // 产品亮点不存在
      if (!this.detailedInfo.productLightSpots) {
        this.detailedInfo.productLightSpots = [""];
      }
      // 硬件说明不存在
      if (!this.detailedInfo.productHexPlains) {
        this.detailedInfo.productHexPlains = [""];
      }
      // 合作案列不存在
      if (!this.detailedInfo.productCases) {
        this.detailedInfo.productCases = [""];
      }
      // 产品附件不存在
      if (!this.detailedInfo.productFileList) {
        this.detailedInfo.productFileList = [""];
      }
      // 应用价值字段存在
      // if (this.detailedInfo.informationProductValues) {
      //   console.log(
      //     this.detailedInfo.informationProductValues,
      //     "informationProductValues"
      //   );
      //   this.detailedInfo.informationProductValues.forEach((el) => {
      //     console.log("处理数据了吗", el);
      //     this.$set(el, "input1", "");
      //     this.$set(el, "input2", "");
      //     el.value = el.value.split(",");
      //     el.input1 = el.value[0];
      //     el.input2 = el.value[1];
      //   });
      // }
    },
    // 处理应用价值
    // dislodge() {
    //   // 应用价值字段存在
    //   if (this.detailedInfo.informationProductValues) {
    //     // 应用价值第一条对象的描述等于空，就什么都不带
    //     if (this.detailedInfo.informationProductValues[0].valueTitle == null) {
    //       this.detailedInfo.informationProductValues = null;
    //     } else {
    //       this.detailedInfo.informationProductValues.forEach((el, index) => {
    //         el.value = [];
    //         if (el.input1) {
    //           el.value.push(el.input1);
    //           delete el.input1;
    //         }
    //         if (el.input2) {
    //           el.value.push(el.input2);
    //           delete el.input2;
    //         }
    //         el.value = el.value.toString();
    //       });
    //     }
    //   }
    // },
    //新增应用价值
    addvalueTitle() {
      this.detailedInfo.informationProductValues.push({
        id: null,
        informationId: this.activeId,
        value: 0,
        valueTitle: null,
      });
    },
    delvalueTitle(index) {
      this.detailedInfo.informationProductValues.splice(index, 1);
    },
    // 应用场景数据查询
    async selectByScenarioName() {
      const res = await selectByScenarioName({
        query: "",
      });
      if (res.code === 200) {
        this.productScenesOptions = res.data;
      }
    },
    // 应用场景添加
    addScenes() {
      this.detailedInfo.productScenes.push("");
    },
    // 应用场景删除
    delScenes(index) {
      this.detailedInfo.productScenes.splice(index, 1);
    },
    // 应用价值滑块改变
    // changeSlider(val) {
    //   console.log(val, "滑块");
    //   this.informationProductValues.value = val;
    // },
    // 产品亮点添加
    addProductLightSpots() {
      this.detailedInfo.productLightSpots.push("");
    },
    // 产品亮点删除
    delProductLightSpots(index) {
      this.detailedInfo.productLightSpots.splice(index, 1);
    },
    // 硬件说明添加
    addProductHexPlains() {
      this.detailedInfo.productHexPlains.push("");
    },
    // 硬件说明删除
    delProductHexPlains(index) {
      this.detailedInfo.productHexPlains.splice(index, 1);
    },
    // 合作案例添加
    addProductCases() {
      this.detailedInfo.productCases.push("");
    },
    // 合作案例删除
    delProductCases(index) {
      this.detailedInfo.productCases.splice(index, 1);
    },
    //上传行业动态pdf (上传文件之前的钩子，参数为上传的文件)
    async beforeUploadprods(file) {
      this.file = file;
      this.filename = file.name;
      // 要求上传格式
      let format = file.name.split(".")[1];

      let formatList = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"];

      const extension = formatList.indexOf(format);
      if (extension == -1) {
        this.$message.warning("上传模板不支持此格式");
        return;
      }
      // 异步读取文件机制
      let reader = new FileReader();
      // 读取文件内容，结果用data:url的字符串形式表示
      reader.readAsDataURL(file);

      let that = this;
      // readAsDataURL 方法会读取指定的 Blob 或 File 对象，并生成data URl(base64编码)
      reader.onload = function () {
        that.fileData = reader.result;
      };
      return false;
    },
    //上传行业动态
    uploadprods(params, index) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: "ginseng-wisdom-1300257084" /* 填写自己的 bucket，必须字段 */,
          Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
          Key:
            "system_img/" +
            params.file
              .name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */,
        })
        .then((res) => {
          if (res.statusCode === 200) {
            // this.detailedInfo.productFileList[index].name = res.Location;
            // vue2无法监控数组的改变
            this.$set(this.detailedInfo.productFileList, index, res.Location);
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();
          console.log("上传出错", err);
        });
    },
    //新增行业动态附件
    addUpload() {
      // this.detailedInfo.productFileList.push({
      //   name: "",
      // });
      this.detailedInfo.productFileList.push("");
    },
    //删除行业动态附件
    delUpload(index) {
      this.detailedInfo.productFileList.splice(index, 1);
    },
  },
  created() {
    this.selectByScenarioName(); //应用场景数据查询
    // this.handleData();
  },
  mounted() {
    document.getElementsByClassName(
      "detailed_information_form"
    )[0].style.minHeight = document.body.clientHeight - 262 + "px";
  },
};
</script>

<style lang="less" scoped>
.detailed_information {
  background-color: #fff;
  height: 100%;
  /deep/.text {
    height: 213px !important;
  }

  .detailed_information_form {
    // height: 680px;
    overflow: auto;
  }

  .bottom {
    border-top: 1px solid #dbdbdb;
    background: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    .el-button {
      margin: 0 20px;
    }
  }
}
// /deep/ .el-tabs__content{
//     height:750px
//   }
// .general_inputs {
//   width: 585px;
//   border: 1px solid #d4d4d4;
//   padding: 14px 20px;
.input_flex {
  display: flex !important;
  // justify-content: space-between;
  padding: 7px 0px;
  .general_inputs {
    width: 585px;
    border: 1px solid #d4d4d4;
    padding: 14px 20px;
  }
  .valueType_flex {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }
  span {
    // padding-left: 15px;
    font-size: 20px;
    cursor: pointer;
  }
  .el_buttom {
    position: relative;
    left: -110px;
  }
}
// }
::v-deep .textarea .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 480px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .percentage .el-input {
  width: 200px;
  height: 40px;
}
.file_flex {
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}
/deep/.el-upload--text {
  width: 20px;
}
</style>
