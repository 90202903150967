<template>
  <div>
    <div class="essential_information">
      <div class="essential_information_form">
        <el-form
          v-if="isShow"
          :model="essentialInfor"
          ref="ruleFormd"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="产品名称:" prop="productName">
            <el-input
              v-model="essentialInfor.productName"
              placeholder="请输入产品名称"
              style="width: 627px"
            />
          </el-form-item>
          <!-- 新增-产品类型 -->
          <el-form-item label="产品类型:" prop="productType">
            <el-select
              v-model="essentialInfor.productType"
              placeholder="请选择"
              style="width: 627px"
            >
              <el-option
                v-for="item in productTypeOption"
                :key="item.codeId"
                :label="item.codeName"
                :value="item.codeName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 新增-产品功能 -->
          <el-form-item label="产品功能:" prop="informationProducts">
            <div class="general_inputs">
              <div
                class="input_flex"
                v-for="(item, index) in essentialInfor.informationProducts"
                :key="index"
              >
                <div style="display: flex; align-items: center">
                  <img-big-upload
                    :imageShow.sync="item.productImage"
                    :addTitleShow="false"
                    @removeImg="
                      (res) => {
                        return removeImgDianprod(res, index);
                      }
                    "
                    @uploadOneImgShow="
                      (res) => {
                        return uploadOneImgShowDianprod(res, index);
                      }
                    "
                  />
                  <div class="textarea" style="margin-left: 10px">
                    <el-input
                      v-model="item.productFunctionDescribe"
                      type="textarea"
                      placeholder="请输入功能描述"
                      maxlength="10"
                      show-word-limit
                    />
                  </div>
                </div>
                <div style="display: flex; font-size: 24px; margin-left: 20px">
                  <span
                    v-if="index == 0"
                    style="color: #4e93fb"
                    @click="addDescribe"
                    ><i class="el-icon-circle-plus" style="font-size: 24px"></i
                  ></span>
                  <span
                    v-if="index > 0"
                    style="color: #ff7575"
                    @click="delDescribe(index)"
                    ><i class="el-icon-delete" style="font-size: 24px"></i
                  ></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="产品简介:" prop="productIntro">
            <el-input
              style="width: 627px"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              placeholder="请输入产品简介"
              v-model="essentialInfor.productIntro"
            />
          </el-form-item>
          <!-- 新增-所属企业 -->
          <el-form-item label="所属企业:" prop="companyFullName">
            <el-select
              v-model="essentialInfor.companyFullName"
              filterable
              remote
              reserve-keyword
              placeholder="请选择"
              @change="syncCompanyWebsite"
              :remote-method="remoteMethod"
            >
              <el-option
                v-for="item in options"
                :key="item.companyCode"
                :label="item.companyFullName"
                :value="item.companyFullName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- remote -->
          <!-- <el-form-item label="所属企业:">
            <el-select
              v-model="essentialInfor.companyCode"
              filterable
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loading"
              @change="itemaa"
              style="width:470px"
              :clearable="true"
            >
              <el-option
                v-for="(item,index) in options"
                :key="index"
                :label="item.companyFullName"
                :value="item.companyCode"
              >
              </el-option>
            </el-select>
            <el-select
              @change="itemaa"
              v-model="essentialInfor.companyName"
              filterable
              style="width: 470px"
              placeholder="请输入"
            >
              <el-option
                v-for="item in EnterpriseList"
                :key="item.companyCode"
                :label="item.companyFullName"
                :value="item.companyWebsite"
              />
            </el-select>
          </el-form-item> -->
          <!-- 原:企业链接 -->
          <el-form-item label="产品链接:" prop="companyWebsite">
            <el-link
              :href="essentialInfor.companyWebsite"
              target="_blank"
              :underline="false"
              >{{ essentialInfor.companyWebsite }}</el-link
            >
            <i
              class="jr-iconjr-icon-edit1"
              style="margin-left: 24px"
              @click="isLinkShow = true"
            ></i>
            <div class="input_button" v-if="isLinkShow">
              <el-input
                v-model="essentialInfor.companyWebsite"
                style="width: 470px"
              />
              <el-button
                type="primary"
                style="height: 40px; margin-left: 24px"
                @click="isLinkShow = false"
                >保存</el-button
              >
            </div>
          </el-form-item>
          <!-- 原:产品图片 -->
          <el-form-item label="封面图:" prop="productCoverList">
            <UpLoadImage
              @upLoadImgList="clickTu"
              @upOldLoadImgList="clickOldTu"
              :upLoadImgList.sync="essentialInfor.productCoverList"
              :upOldLoadImgList.sync="essentialInfor.productImg"
            />
          </el-form-item>
          <!-- 新增-分享图 -->
          <el-form-item label="分享图:" prop="shareImages">
            <UpLoadImage
              @upLoadImgList="clickTuShare"
              @upOldLoadImgList="clickOldTuShare"
              :upLoadImgList.sync="essentialInfor.shareImages"
              :upOldLoadImgList.sync="essentialInfor.oldShareImages"
            />
          </el-form-item>
          <!-- 新增-产品视频 -->
          <el-form-item label="视频:" prop="videos">
            <div class="cover">
              <div class="img_box" v-if="essentialInfor.videoResults">
                <uploadVideo
                  :videos="essentialInfor.videoResults"
                  :videosId="essentialInfor.videos"
                  @uploadOneImg="uploadOneImgvideo"
                  @removeImg="removeImgvideo"
                ></uploadVideo>
              </div>
              <div class="img_box" v-else>
                <uploadVideo
                  @uploadOneImg="uploadOneImgvideo"
                  @removeImg="removeImgvideo"
                ></uploadVideo>
              </div>
              <div v-if="essentialInfor.videoResults">缩略图</div>
              <div v-if="essentialInfor.videoResults" class="thumbnail">
                <div class="thumbnail_box">
                  <img
                    :src="essentialInfor.videoResults[0].coverImage"
                    alt=""
                  />
                  <div>视频尺寸：442*226 px</div>
                  <div>图片大小：4MB</div>
                  <div>展示位置：首页</div>
                </div>
                <div class="thumbnail_box">
                  <img
                    :src="essentialInfor.videoResults[0].coverImage"
                    alt=""
                  />
                  <div>视频尺寸：442*226 px</div>
                  <div>图片大小：5MB</div>
                  <div>展示位置：文章详情页</div>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="视频播放次数">
            <div class="number_top">
              <span>{{ essentialInfor.loopCount }}</span>
            </div>
            <div class="number_loop">
              <el-input-number v-model="essentialInfor.loopCount" :min="1" :max="1000000"></el-input-number>
              <el-switch
                style="margin-left: 20px;"
                v-model="essentialInfor.loopStatus"
                active-color="#3562ff"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
              <div style="position: relative; top: 10px; left: 10px" v-if="essentialInfor.loopStatus == 1">
                已启用
              </div>
            </div>
            <span style="color: #bebebe">此播放次数为虚假数据，如启用，需展示在前台</span>
          </el-form-item> -->
          <!-- 新增-交付类型 -->
          <el-form-item label="交付类型:" prop="productDelivery">
            <!-- <el-select v-model="essentialInfor.productDelivery" placeholder="请选择">
              <el-option
                v-for="item in productDeliveryOption"
                :key="item.codeId"
                :label="item.codeName"
                :value="item.codeName">
              </el-option>
            </el-select> -->
            <el-radio
              v-model="essentialInfor.productDelivery"
              label="解决方案交付"
              >解决方案交付</el-radio
            >
            <el-radio
              v-model="essentialInfor.productDelivery"
              label="应用软件交付"
              >应用软件交付</el-radio
            >
            <el-radio v-model="essentialInfor.productDelivery" label="硬件交付"
              >硬件交付</el-radio
            >
            <el-radio v-model="essentialInfor.productDelivery" label="云API交付"
              >云API交付</el-radio
            >
            <el-radio
              v-model="essentialInfor.productDelivery"
              label="离线SDK交付"
              >离线SDK交付</el-radio
            >
            <el-radio v-model="essentialInfor.productDelivery" label="数据交付"
              >数据交付</el-radio
            >
            <el-radio v-model="essentialInfor.productDelivery" label="按需交付"
              >按需交付</el-radio
            >
            <el-radio v-model="essentialInfor.productDelivery" label="其他"
              >其他</el-radio
            >
          </el-form-item>
          <!-- <el-form-item label="行业类别:" prop="solutionIndustry">
            <multiple-choice
              :code.sync="essentialInfor.solutionIndustry"
              tag="行业"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="领域类别:" prop="domainCategory">
            <multiple-choice
              :code.sync="essentialInfor.domainCategory"
              tag="领域"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="技术类别:" prop="solutionTechnology">
            <multiple-choice
              :code.sync="essentialInfor.solutionTechnology"
              tag="技术"
              @updateCode="updateCode"
            />
          </el-form-item>
                <el-form-item label="领域标签:" v-if="enterpriseType">
            <el-input v-model="essentialInfor.wisdomTag" style="width:60%"  type="textarea" :autosize="{minRows:2,maxRows:8}"></el-input>
          </el-form-item>
          <el-form-item label="行业标签:" v-if="enterpriseType">
            <el-input v-model="essentialInfor.IndustryTag" style="width:60%"  type="textarea" :autosize="{minRows:2,maxRows:8}"></el-input>
          </el-form-item>
          <el-form-item label="技术标签:" v-if="enterpriseType">
            <el-input v-model="essentialInfor.aiTag" style="width:60%"  type="textarea" :autosize="{minRows:2,maxRows:8}"></el-input>
          </el-form-item>
          <el-form-item label="产品类型:" prop="productType">
            <multiple-choice
              :code.sync="essentialInfor.productType"
              tag="产品"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="技术驱动:" prop="productAi">
            <multiple-choice
              :code.sync="essentialInfor.productAi"
              tag="驱动"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="交付类型:" prop="solutionDelivery">
            <multiple-choice
              :code.sync="essentialInfor.solutionDelivery"
              tag="交付"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="关键词：" v-if="enterpriseType" label-width="100px">
            <el-input
              v-model="essentialInfor.antistop"
              style="width: 60%"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
            ></el-input>
          </el-form-item> -->
        </el-form>
        <div class="ess_people" v-if="enterpriseType">
          <div class="ess_people_list">
            创建人：{{ essentialInfor.createName }}
          </div>
          <div class="ess_people_list">
            修改人：{{ essentialInfor.updateName }}
          </div>
          <div class="ess_people_list">
            <!-- 创建时间：{{ essentialInfor.creationTime}} -->
            创建时间：{{ essentialInfor.createTime }}
          </div>
          <div class="ess_people_list">
            修改时间：{{ essentialInfor.updateTime }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom" v-if="this.enterpriseType">
      <el-button type="primary" @click="detailedUpdate">确定</el-button>
      <el-button type="info" @click="cancelClick">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import UpLoadImage from "../UpLoadImage";
import multipleChoice from "../com/multipleChoice.vue";
import uploadVideo from "../../../components/uploadVideo.vue";
// 企业名称查询 / 产品基本信息修改 / 获取产品附件表格数据
import {
  getEnterpriseAll,
  updateEssenti,
  smartFind,
} from "../../../api/product";
import { getCodeByStatus } from "../../../api/enterprise";
export default {
  name: "essentialInformation",
  components: {
    UpLoadImage,
    multipleChoice,
    uploadVideo,
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    productCode() {
      return this.$route.query.productCode;
    },
  },
  props: {
    // 表单数据
    essentialInfor: {
      type: Object,
    },
    // 类型-编辑/新增
    enterpriseType: {
      type: Number,
    },
  },
  watch: {
    "essentialInfor.productCode": {
      handler(newVal) {
        if (this.enterpriseType && newVal) {
          this.isShow = true;
          // 注释就可以在编辑中选中别的不然不行
          this.options = [
            {
              companyCode: this.essentialInfor.companyCode,
              companyFullName: this.essentialInfor.companyFullName,
            },
          ];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    // this.getEnAllList();
    // 编辑
    // setTimeout(() => {
    // if (!this.enterpriseType) {
    this.isShow = true;
    // }
    // else {
    // this.isShow = true;
    // }
    // }, 1000);
    // 所属企业查询
    this.companyCodeSearch();
    // 产品类型查询
    this.getCodeByStatus();
    // 交付类型查询
    this.getCodeByType();
  },
  data() {
    return {
      isShow: false,
      loading: false,
      productTypeOption: [], //产品类型数据
      options: [], //所属企业数据
      productDeliveryOption: [], //交付类型数据
      isLinkShow: false, //企业链接显示
      EnterpriseList: [], //企业名称
      rules: {
        productName: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        productDelivery: [
          { required: true, message: "请选择交付类型", trigger: "blur" },
        ],
        // domainCategory: [
        //   {
        //     required: true,
        //     message: "请至少选择一个技术类别",
        //     trigger: "change",
        //   },
        // ],
        // solutionIndustry: [
        //   {
        //     required: true,
        //     message: "请至少选择一个行业类别",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  methods: {
    //取消
    cancelClick() {
      this.$router.go(-1);
    },
    //企业名称选择链接变化
    itemaa(val) {
      let obj = {};
      obj = this.EnterpriseList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.companyWebsite === val; //筛选出匹配数据
      });
      this.essentialInfor.companyName = obj.companyFullName; //获取的 name
      this.essentialInfor.companyCode = obj.companyCode;
      this.essentialInfor.solutionLink = val;
    },
    //获取企业名称
    async getEnAllList() {
      const res = await getEnterpriseAll();
      if (res.code == 200) {
        this.EnterpriseList = res.data;
      }
    },
    //保存编辑
    detailedUpdate() {
      // 将token和表单合并
      Object.assign(this.essentialInfor, {
        Authorization: this.Authorization,
      });
      this.$refs.ruleFormd.validate(async (valid) => {
        if (valid) {
          //行业
          if (this.essentialInfor.solutionIndustryChild) {
            this.essentialInfor.solutionIndustry =
              this.essentialInfor.solutionIndustry.concat(
                this.essentialInfor.solutionIndustryChild
              );
          }
          //领域
          if (this.essentialInfor.domainCategoryChild) {
            this.essentialInfor.domainCategory =
              this.essentialInfor.domainCategory.concat(
                this.essentialInfor.domainCategoryChild
              );
          }
          //技术
          if (this.essentialInfor.solutionTechnologyChild) {
            this.essentialInfor.solutionTechnology =
              this.essentialInfor.solutionTechnology.concat(
                this.essentialInfor.solutionTechnologyChild
              );
          }
          //产品
          if (this.essentialInfor.productTypeChild) {
            this.essentialInfor.productType =
              this.essentialInfor.productType.concat(
                this.essentialInfor.productTypeChild
              );
          }
          const res = await updateEssenti(this.essentialInfor);
          if (res.code == 200) {
            this.$message.success("基本信息修改成功");
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请完善基本信息!");
        }
      });
    },
    //上传组件图片地址（封面图）
    clickOldTu(val) {
      this.essentialInfor.productCoverList = val;
    },
    clickTu(val) {
      this.essentialInfor.productImg = val;
    },
    //上传组件图片地址（分享图）
    clickOldTuShare(val) {
      this.essentialInfor.oldShareImages = val;
    },
    clickTuShare(val) {
      this.essentialInfor.shareImages = val;
    },
    updateCode(val, tag) {
      switch (tag) {
        case "行业":
          this.essentialInfor.solutionIndustryChild = val;
          break;

        case "领域":
          this.essentialInfor.domainCategoryChild = val;
          break;

        case "技术":
          this.essentialInfor.solutionTechnologyChild = val;
          break;

        case "产品":
          this.essentialInfor.productTypeChild = val;
          break;

        default:
          break;
      }
    },
    // 企业名称
    remoteMethod(query) {
      this.loading = true;
      setTimeout(async () => {
        this.loading = false;
        // const companyName = query
        const res = await smartFind({ companyName: query });
        this.options = res.data;
      }, 200);
    },
    changeLink() {
      const data = this.essentialInfor.companyCode;
      var i1 = this.options.findIndex((value) => value.companyCode == data);
      this.essentialInfor.solutionLink = this.options[i1].companyWebsite;
      this.essentialInfor.companyFullName = this.options[i1].companyFullName;
    },
    // 所属企业数据查询
    async companyCodeSearch() {
      const res = await smartFind();
      this.options = res.data;
    },

    // 根据所属企业的链接来回显产品链接
    syncCompanyWebsite(val) {
      this.options.forEach((item) => {
        if (item.companyFullName == val) {
          this.essentialInfor.companyWebsite = item.companyWebsite;
          this.essentialInfor.companyCode = item.companyCode;
        }
      });
    },
    // 产品类型数据查询
    async getCodeByStatus() {
      const res = await getCodeByStatus({
        code: "049",
      });
      if (res.code == 200) {
        this.productTypeOption = res.data;
      }
    },
    // 交付类型数据查询
    async getCodeByType() {
      const res = await getCodeByStatus({
        code: "020",
      });
      if (res.code == 200) {
        this.productDeliveryOption = res.data;
      }
    },
    // 产品功能相关
    removeImgDianprod(val, index) {
      this.essentialInfor.informationProducts[index].productImage = "";
    },
    uploadOneImgShowDianprod(val, index) {
      this.essentialInfor.informationProducts[index].productImage = val;
    },
    //新增产品功能
    addDescribe() {
      this.essentialInfor.informationProducts.push({
        id: null,
        informationId: this.activeId,
        productFunctionDescribe: null,
        productImage: "",
      });
    },
    // 删除产品功能
    delDescribe(index) {
      this.essentialInfor.informationProducts.splice(index, 1);
    },
    //视频上传
    uploadOneImgvideo(val) {
      this.essentialInfor.videos = val;
    },
    removeImgvideo(val) {
      this.essentialInfor.video = val;
    },
  },
  mounted() {
    document.getElementsByClassName(
      "essential_information_form"
    )[0].style.minHeight = document.body.clientHeight - 260 + "px";
  },
};
</script>

<style scoped lang='less'>
.essential_information {
  width: 100%;
  .ess_people {
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    margin: 20px 30px;
    align-content: center;

    .ess_people_list {
      width: 100%;
      margin: 4px 0;
      color: #7c7f8e;
      font-size: 12px;
    }
  }

  .essential_information_form {
    // height: 680px;
    overflow: auto;
  }

  // /deep/.el-upload-list__item {
  //   width: 80px !important;
  //   height: 80px !important;
  //   line-height: 80px !important;
  // }

  /deep/.el-upload--picture-card {
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
// /deep/ .el-tabs__content{
//   height:750px
// }
.general_inputs {
  width: 585px;
  // width: 826px;
  border: 1px solid #d4d4d4;
  padding: 14px 20px;
  .input_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0px;
    .valueType_flex {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }
    span {
      padding-left: 30px;
      font-size: 20px;
      cursor: pointer;
    }
    .el_buttom {
      position: relative;
      left: -110px;
    }
  }
}
::v-deep .textarea .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 480px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .percentage .el-input {
  width: 200px;
  height: 40px;
}

::v-deep .textarea .el-textarea {
  position: relative;
  display: inline-block;
  width: 480px;
  vertical-align: bottom;
  font-size: 14px;
}
/deep/.el-input {
  width: 627px;
  height: 40px;
}
/deep/.el-select {
  width: 344px !important;
}
.cover {
  display: flex;
  flex-direction: column;
}
.thumbnail {
  display: flex;
  color: #bebebe;
}
.thumbnail img {
  width: 143.23px;
  height: 81.63px;
  border-radius: 4px;
}
.thumbnail video {
  width: 160.28px;
  height: 91.35px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}
.number_top {
  width: 96px;
  height: 40px;
  background: #f4f4f4;
  border-radius: 2px;
  text-align: center;
  line-height: 40px;
  color: #bebebe;
  margin-bottom: 20px;
}
.number_loop {
  display: flex;
  align-items: flex-end;
}
::v-deep .number_loop .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 181px;
}
</style>
